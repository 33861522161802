import Icon from "react-crypto-icons";

function Currency(props) {

  return (
    <span>
      <Icon name={props.currency.toLowerCase()} size={20} />{props.currency.toUpperCase()}
    </span>

  );
}

export default Currency;