import React, {useState} from 'react';
import CurrencyDropdown from "./CurrencyDropdown";
import {Button, ButtonGroup, ButtonToolbar, Dropdown, DropdownButton, Spinner} from "react-bootstrap";

function PathFilter(props) {

  const [from, setFrom] = useState(props.filter.from);
  const [to, setTo] = useState(props.filter.to);
  const [jumps, setJumps] = useState(props.filter.jumps);

  const filterHandler = (event) => {
    event.preventDefault();

    const filter = {
      from: from,
      to: to,
      jumps: jumps,
    };

    props.filterHandler(filter);
  };

  function jumpHandler(event) {
    setJumps(event)
  }

  function fromHandler(event) {
    setFrom(event)
  }

  function toHandler(event) {
    setTo(event)
  }

  return (
    <ButtonToolbar aria-label="Filters for paths" className="justify-content-md-center">
      <ButtonGroup className="me-2" aria-label="First group">
        <CurrencyDropdown
          label="From:"
          currencies={props.currencies}
          changeHandler={fromHandler}
          initialCurrency={from}
        />
        <CurrencyDropdown
          label="To:"
          currencies={props.currencies}
          changeHandler={toHandler}
          initialCurrency={to}
        />
        <DropdownButton
          title={"Jumps: " + jumps}
          onSelect={jumpHandler}
          variant="outline-primary"
        >
          <Dropdown.Item eventKey="1">1</Dropdown.Item>
          <Dropdown.Item eventKey="2">2</Dropdown.Item>
          <Dropdown.Item eventKey="3">3</Dropdown.Item>
        </DropdownButton>
      </ButtonGroup>
      <ButtonGroup className="me-2" aria-label="Second group">
        <Button
          variant="primary"
          onClick={filterHandler}
          disabled={!props.enabled}
        >
          Refresh
        </Button>
      </ButtonGroup>
      <Spinner animation="border" variant="primary" hidden={props.enabled} />
    </ButtonToolbar>

  )
}

export default PathFilter;