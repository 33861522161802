import { useState, useEffect } from 'react';

import MeetupList from '../components/meetups/MeetupList';
import {Spinner} from "react-bootstrap";

function AllMeetupsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedMeetups, setLoadedMeetups] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      //'https://react-getting-started-48dec-default-rtdb.firebaseio.com/meetups.json'
      //'meetups.json'
      '3.json'
      //'http://exarb-loadb-ajghhv4ejg21-48e9cb92783f36a4.elb.ap-southeast-2.amazonaws.com:8080/api/md/paths/aud-aud/3'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const meetups = [];

        for (const key in data) {
          const meetup = {
            id: key,
            ...data[key]
          };

          meetups.push(meetup);
        }

        setIsLoading(false);
        setLoadedMeetups(meetups);
      })
      .catch(err => {
        throw new Error(err)
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <Spinner animation="border" variant="primary" />
      </section>
    );
  }

  return (
    <section>
      <h1>Paths</h1>
      <MeetupList meetups={loadedMeetups} />
    </section>
  );
}

export default AllMeetupsPage;
