import { Route, Switch } from 'react-router-dom';

import AllMeetupsPage from './pages/AllMeetups';
import TradePathsPage from './pages/TradePaths';
import NewMeetupPage from './pages/NewMeetup';
import FavoritesPage from './pages/Favorites';
import Layout from './components/layout/Layout';
import {useEffect} from "react";
import TradeLive from "./pages/TradeLive";
import Exchanges from "./pages/Exchanges";
import PathsPage from "./pages/Paths";
import Status from "./pages/Status";
import {withAuthenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import awsConfig from './aws-exports';
import Amplify from "aws-amplify";
import OpportunitiesPage from "./pages/Opportunities";
import ProfitPage from "./pages/Profit";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

function App({ signOut, user }) {
  useEffect(() => {
    document.title = "Exarbo Trading"
  }, []);

  return (
    <Layout user={user} signOut={signOut}>
      <Switch>
        <Route path='/' exact>
          <PathsPage />
        </Route>
        <Route path='/opportunities' exact>
          <OpportunitiesPage />
        </Route>
        <Route path='/profit' exact>
          <ProfitPage />
        </Route>
        <Route path='/paths-old' exact>
          <TradePathsPage />
        </Route>
        <Route path='/live'>
          <TradeLive />
        </Route>
        <Route path='/exchanges'>
          <Exchanges />
        </Route>
        <Route path='/status'>
          <Status />
        </Route>
        <Route path='/old'>
          <AllMeetupsPage />
        </Route>
        <Route path='/new-path'>
          <NewMeetupPage />
        </Route>
        <Route path='/favorites'>
          <FavoritesPage />
        </Route>
      </Switch>
    </Layout>
  );
}

export default withAuthenticator(App);