import {Button, Card, CardGroup, Container, ListGroup} from "react-bootstrap";
import Currency from "../meetups/Currency";
import Exchange from "../ui/Exchange";
import NumberFormat from "react-number-format";
import TimeAgo from "react-timeago";

function ProfitContainer(props) {
  return (
    <Container>
      {props.paths.map((path) => (
        <CardGroup className="shadow">
          <Card className="text-center">
            <ListGroup variant="flush">
            {path.steps.map((step) => (
              <ListGroup.Item>
                <Currency currency={step.currencyPair.split('/')[0]} /> <Exchange exchange={step.exchangeName} /> <Currency currency={step.currencyPair.split('/')[1]} />
              </ListGroup.Item>
            ))}
            </ListGroup>
          </Card>
          <Card>
            <Card.Body>
              <Card.Text>
                <div>Profit (USD):
                  <NumberFormat
                    value={path.profitUSD}
                  />
                </div>
                <div>Profit ({path.profitCurrency}):
                  <NumberFormat
                    value={path.profit}
                  />
                </div>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {/*<small className="text-muted">Last updated <TimeAgo date={path.steps[0].timestamp} /></small>*/}
            </Card.Footer>
          </Card>
        </CardGroup>
      ))}
    </Container>
  );
}

export default ProfitContainer;