import {Chart} from "react-google-charts";
import {useRef, useState} from "react";
import {Button} from "react-bootstrap";
import OrderBookRetriever from "../components/md/OrderBookRetriever";

function TradeLive(props) {

  const clientRef = useRef();

  // TODO: Makes these dynamic
  const [exchange, setExchange] = useState('binance');
  const [base, setBase] = useState('BTC');
  const [counter, setCounter] = useState('USDT');

  const [chartData, setChartData] = useState([])

  const refreshHandler = (msg) => {
    // TODO: Move this to OrderBookRetriever ?
    msg = {
      exchange: exchange,
      from: base,
      to: counter
    }
    clientRef.current.sendMessage('/app/connections', JSON.stringify(msg))

  }

  const orderBookHandler = (orderBook) => {
    const newChartData = [['Price', 'Asks', 'Bids']]
    let cumAmount = 0
    orderBook.asks.forEach((ask) => {
      cumAmount = cumAmount + ask.remainingAmount
      newChartData.push([ask.limitPrice, cumAmount, 0])
    })
    cumAmount = 0
    orderBook.bids.forEach((bid) => {
      cumAmount = cumAmount + bid.remainingAmount
      newChartData.push([bid.limitPrice, 0, cumAmount])
    })
    setChartData(newChartData)
  }

  // TODO: Give a blank space for chart instead of error before data is loaded
  return (
    <div>
      <Button onClick={(msg) => refreshHandler(msg)}>Connect</Button>
      <OrderBookRetriever
        orderBookHandler={orderBookHandler}
        exchange={exchange}
        base={base}
        counter={counter}
        ref={clientRef}
      />
      <Chart
        width={800}
        height={'600px'}
        chartType="SteppedAreaChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          title: exchange.charAt(0).toUpperCase() + exchange.slice(1) + ' ' + base + '/' + counter +' Order Book',
          // TODO: set minValue on hAxis?
          hAxis: { title: 'Price ('+counter+')', titleTextStyle: { color: '#333' } },
          vAxis: { title: 'Amount ('+base+')', titleTextStyle: { color: '#333' } },
          // For the legend to fit, we make the chart area smaller
          chartArea: { width: '70%', height: '80%' },
        }}
      />
    </div>
  )
}

export default TradeLive