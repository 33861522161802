import {useEffect, useState} from "react";
import {Col, Container, Nav, Row, Spinner, Tab} from "react-bootstrap";
import ExchangeStatus from "../components/status/ExchangeStatus";

function Status(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState({});

  const apiPath = "https://tickers.api.exarbo.com/"

  useEffect(() => {
    setIsLoading(true);
    fetch(
      apiPath + 'status/exchanges'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStatus(data)
        setIsLoading(false)
      })
      .catch(err => {
        throw new Error(err)
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <Spinner animation="border" variant="primary" />
      </section>
    );
  }

  return (
  <Tab.Container id="left-tabs-example" defaultActiveKey={Object.keys(status)[0]} >
    <Row >
      <Col sm={2}>
        <Nav variant="pills" className="flex-column" >
          {Object.keys(status).map((key) => (
          <Nav.Item style={{cursor:'pointer'}}>
            <Nav.Link eventKey={key}>{key}</Nav.Link>
          </Nav.Item>
          ))}
        </Nav>
      </Col>
      <Col sm={10}>
        <Tab.Content>
          {Object.keys(status).map((key) => (
          <Tab.Pane eventKey={key}>
            <ExchangeStatus details={status[key]} />
          </Tab.Pane>
          ))}
        </Tab.Content>
      </Col>
    </Row>
  </Tab.Container>
  )

}

export default Status