import { useState, useEffect } from 'react';

import {Container, Spinner} from "react-bootstrap";
import PathFilter from "../components/paths/PathFilter";
import PathContainerOld from "../components/paths/PathContainerOld";

function TradePathsPage() {
  const [isLoadingFilter, setIsLoadingFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedPaths, setLoadedPaths] = useState([]);
  const [loadedCurrencies, setLoadedCurrencies] = useState({});
  const [enabled, setEnabled] = useState(true)

  const [filter, setFilter] = useState(
    {
      from:'USDT',
      to: 'USDT',
      jumps: 2
    }
  );

  const apiPath = 'http://exarb-loadb-ajghhv4ejg21-48e9cb92783f36a4.elb.ap-southeast-2.amazonaws.com:8080/api';

  function filterHandler(filter) {
    setEnabled(false)
    setFilter(filter)
  }

  useEffect(() => {
    //setIsLoading(true);
    fetch(
      apiPath + '/ref/list/currencies'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setIsLoadingFilter(true)
        setLoadedCurrencies(data)
        setIsLoadingFilter(false)
      })
      .catch(err => {
        throw new Error(err)
      });
  }, []);
  useEffect(() => {
    //setIsLoading(true);
    fetch(
      //'3.json'
      apiPath + '/md/paths/' +
      filter.from.toLowerCase() + '-' +
      filter.to.toLowerCase() + '/' +
      filter.jumps
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const paths = [];

        for (const key in data) {
          const path = {
            id: key,
            ...data[key]
          };

          paths.push(path);
        }

        setEnabled(true)
        setIsLoading(false)
        setLoadedPaths(paths)

      })
      .catch(err => {
        throw new Error(err)
      });
  }, [filter]);

  if (isLoading || isLoadingFilter) {
    return (
      <section>
        <Spinner animation="border" variant="primary" />
      </section>
    );
  }

  return (
    <Container>
      <PathFilter
        currencies={loadedCurrencies}
        filterHandler={filterHandler}
        filter={filter}
        enabled={enabled}
      />
      <PathContainerOld
        paths={loadedPaths}
        enabled={enabled}
      />
    </Container>
  );
}

export default TradePathsPage;
