import React, {forwardRef, useState} from "react";
import {Dropdown, FormControl} from "react-bootstrap";

function CurrencyDropdown(props) {
  // TODO: Initialise
  const [currency, setCurrency] = useState(props.initialCurrency);

  // The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  function currencyHandler(event) {
    setCurrency(event)
    props.changeHandler(event)
  }

  return (
    <Dropdown
      onSelect={currencyHandler}
    >
      <Dropdown.Toggle id="dropdown-custom-components"
                       variant="outline-primary">
        {props.label} {currency}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {/*TODO: selected initial currency*/}
        {props.currencies.map((currency) => (
          <Dropdown.Item eventKey={currency.code}>{currency.name}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default forwardRef(CurrencyDropdown)