import {AgGridColumn, AgGridReact} from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
//import {TiTick, TiTimes} from "react-icons/ti";
//import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
//import 'ag-grid-community/dist/styles/ag-theme-balham.css';

function Exchanges(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [loadedTableData, setLoadedTableData] = useState({});

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  // https://github.com/ag-grid/ag-grid/issues/1692
  /**
   * Cram all columns to fit into the grid initially.
   */
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  /**
   * Auto-size all columns once the initial data is rendered.
   */
  const autoSizeColumns = params => {
    //params.columnApi.autoSizeColumns(true)
    // const colIds = params.columnApi
    //   .getAllDisplayedColumns()
    //   .map(col => col.getColId());
    //
    // params.columnApi.autoSizeColumns(colIds);
    const skipHeader = false
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  // const onBtnUpdate = () => {
  //   document.querySelector('#csvResult').value = gridApi.getDataAsCsv();
  // };

  const apiPath = "https://tickers.api.exarbo.com/"

  function usdFormatter(params) {
    return '\x24' + formatNumber(params.value, 0);
  }
  function coinPriceFormatter(params) {
    return '\x24' + formatNumber(params.value, 2);
  }
  function formatNumber(number, decimals) {
    if (number == null) {
      return null
    }
    return number.toFixed(decimals)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function percentFormatter(params) {
    return parseFloat(params.value).toFixed(2)+"%";
  }

  function booleanFormatter(params) {
    if (params.value) {
      return "TRUE"
    } else {
      return null
    }
    // TODO: Use icons in cells
    // if (params.value) {
    //   return <TiTick />
    // } else {
    //   return <TiTimes />
    // }
  }

  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(
      apiPath + 'metadata/table/coinspread'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedTableData(data)
        setIsLoading(false)
      })
      .catch(err => {
        throw new Error(err)
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <Spinner animation="border" variant="primary" /> This takes about a minute, grab a beer
      </section>
    );
  }

  return (
    <div className="ag-theme-alpine" style={{height: 700}}>
      <div style={{ margin: '10px 0' }}>
        <button onClick={() => onBtnExport()}>
          Export to CSV ya spud
        </button>
        <button onClick={() => autoSizeAll(true)}>
          Auto-Size All (Skip Header)
        </button>
      </div>
      <AgGridReact
        defaultColDef={{
          resizable: true,
        }}
        rowData={loadedTableData.coinTableDataRows}
        onGridReady={onGridReady}
        onFirstDataRendered={autoSizeColumns}>
        <AgGridColumn
          field="market_cap_rank"
          headerName=""
          sortable={true}
          filter={true}
          type="numericColumn"
          pinned="left" />
        <AgGridColumn
          field="symbol"
          headerName="Coin"
          sortable={true}
          filter={true}
          pinned="left" />
        <AgGridColumn
          field="name"
          headerName="Name"
          sortable={true}
          filter={true} />
        <AgGridColumn
          field="price"
          headerName="Price (USD)"
          sortable={true}
          filter={true}
          valueFormatter={coinPriceFormatter}
          type="numericColumn" />
        <AgGridColumn
          field="market_cap"
          headerName="Market Cap (USD)"
          sortable={true}
          filter={true}
          valueFormatter={usdFormatter}
          type="numericColumn" />
        <AgGridColumn
          field="total_volume"
          headerName="Volume (USD)"
          sortable={true}
          filter={true}
          valueFormatter={usdFormatter}
          type="numericColumn" />
        <AgGridColumn
          field="change_perc_24h"
          headerName="Chng% 24h"
          sortable={true}
          filter={true}
          valueFormatter={percentFormatter}
          type="numericColumn" />
        <AgGridColumn
          field="change_perc_30d"
          headerName="Chng% 30d"
          sortable={true}
          filter={true}
          valueFormatter={percentFormatter}
          type="numericColumn" />
        <AgGridColumn
          field="ath_change_perc"
          headerName="ATH Chng%"
          sortable={true}
          filter={true}
          valueFormatter={percentFormatter}
          type="numericColumn" />
        {loadedTableData.gridColumns.map((gridColumn) => (
          <AgGridColumn
            field={gridColumn.field}
            headerName={gridColumn.headerName}
            sortable={true}
            filter={true}
            valueFormatter={booleanFormatter}/>
        ))}
      </AgGridReact>
    </div>
  )

}

export default Exchanges