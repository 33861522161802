import {useEffect, useState} from "react";
import {Container, Spinner} from "react-bootstrap";
import PathContainer from "../components/paths/PathContainer";
import ProfitContainer from "../components/paths/ProfitContainer";

function ProfitPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedOpportunities, setLoadedOpportunities] = useState([]);
  const [enabled, setEnabled] = useState(true)

  //const botApi = process.env.REACT_APP_BOT_API_URL
  const botApi = 'http://localhost:8081'


  useEffect(() => {
    //setIsLoading(true);
    fetch(
      botApi +'/depth/divers'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const paths = [];

        for (const key in data) {
          const path = {
            id: key,
            ...data[key]
          };

          paths.push(path);
        }

        paths.sort((a, b) => (a.profitUSD < b.profitUSD) ? 1 : -1)

        setEnabled(true)
        setIsLoading(false)
        setLoadedOpportunities(paths)

      })
      .catch(err => {
        throw new Error(err)
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <Spinner animation="border" variant="primary" />
      </section>
    );
  }

  return (
    <Container>
      <ProfitContainer
        paths={loadedOpportunities}
        enabled={enabled}
      />
    </Container>
  );
}

export default ProfitPage