import {Table} from "react-bootstrap";
import TimeAgo from "react-timeago";

function ExchangeStatus(props) {

  function usdFormatter(value) {
    return '\x24' + formatNumber(value, 0);
  }
  function formatNumber(number, decimals) {
    if (number == null) {
      return null
    }
    return number.toFixed(decimals)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const notNullable = Object.values(props.details).filter(obj=>!Object.values(obj).includes(null));
  const totalLoaded = notNullable.length
  const notLoaded = Object.keys(props.details).length - totalLoaded

  console.log(notNullable)
  console.log(notNullable.length)

  return (
    <div>
      <div>Currency Pairs: {Object.keys(props.details).length}</div>
      {/*<div>{notNullable}</div>*/}
      <div>Loaded: {totalLoaded}</div>
      <div>Remaining: {notLoaded}</div>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Pair</th>
          <th>Volume (USD)</th>
          <th>Last Ticker</th>
        </tr>
        </thead>
        <tbody>
        {Object.keys(props.details).map((key) => (
        <tr>
          <td>{key}</td>
          <td className="text-end">{usdFormatter(props.details[key].cgExchangeTicker.converted_volume.usd)}</td>
          <td className="text-center">{props.details[key].cachedTicker ? <TimeAgo date={props.details[key].cachedTicker.timestamp} /> : ""}</td>
        </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ExchangeStatus