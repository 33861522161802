import classes from './Exchange.module.css';

function Exchange(props) {

  const fullImage = "/img/exchanges/icons/" +
    props.exchange.toLowerCase() +
    ".png"

  return (
    <span className={classes.box}>
      <img src={fullImage}  alt={props.exchange} />{props.exchange}
    </span>
  );
}

export default Exchange;