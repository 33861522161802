import MainNavigation from './MainNavigation';
import classes from './Layout.module.css';
import {Container} from "react-bootstrap";

function Layout(props) {
  return (
    <Container className="pt-3">
      <MainNavigation user={props.user} signOut={props.signOut} />
      <main className={classes.main}>{props.children}</main>
    </Container>
  );
}

export default Layout;
