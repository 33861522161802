import { useContext } from 'react';
import { Link } from 'react-router-dom';

import classes from './MainNavigation.module.css';
import FavoritesContext from '../../store/favorites-context';
import {Container, Nav, Navbar} from "react-bootstrap";

function MainNavigation(props) {
  const favoritesCtx = useContext(FavoritesContext);

  return (
    // <header className={classes.header}>
    //   <div className={classes.logo}>Exarbo Trading</div>
    //   <nav>
    //     <ul>
    //       <li>
    //         <Link to='/'>Paths</Link>
    //       </li>
    //       <li>
    //         <Link to='/live'>Live</Link>
    //       </li>
    //       {/*<li>*/}
    //       {/*  <Link to='/new-path'>Add New Path</Link>*/}
    //       {/*</li>*/}
    //       {/*<li>*/}
    //       {/*  <Link to='/favorites'>*/}
    //       {/*    My Favorites*/}
    //       {/*    <span className={classes.badge}>*/}
    //       {/*      {favoritesCtx.totalFavorites}*/}
    //       {/*    </span>*/}
    //       {/*  </Link>*/}
    //       {/*</li>*/}
    //     </ul>
    //   </nav>
    // </header>
    <Navbar bg="dark" variant="dark" className="rounded" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          {/*<img*/}
          {/*  alt=""*/}
          {/*  src="/img/logo/logo.png"*/}
          {/*  width="30"*/}
          {/*  height="30"*/}
          {/*  className="d-inline-block align-top"*/}
          {/*/>{' '}*/}
          Exarbo Trading
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/">Paths</Nav.Link>
          <Nav.Link href="/opportunities">Opportunities</Nav.Link>
          <Nav.Link href="/profit">Profit</Nav.Link>
          <Nav.Link href="/live" disabled>Live</Nav.Link>
          <Nav.Link href="/exchanges">Exchanges</Nav.Link>
          <Nav.Link href="/status">Status</Nav.Link>
        </Nav>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Signed in as: {props.user.attributes.email} <a onClick={props.signOut}>Logout</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavigation;
