import SockJsClient from "react-stomp";
import {forwardRef} from "react";


function OrderBookRetriever(props, ref) {

  const orderBookHandler = (msg) => {
    props.orderBookHandler(msg)
  }

  if (props.base != null && props.counter != null && props.exchange != null) {

    return (
      <SockJsClient url='http://localhost:8080/gs-guide-websocket'
                    topics={['/topic/exchange/'+props.exchange+'/book/'+props.base+'/'+props.counter]}
                    onMessage={(msg) => orderBookHandler(msg)}
                    ref={ref} />
    )
  }

}

export default forwardRef(OrderBookRetriever)